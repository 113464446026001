<template>
  <div class="mask-dialog">
    <div class="dialog-box">
      <div class="header">
        <div>{{ detailData.jobName }}</div>
        <div class="close curr" @click="close">x</div>
      </div>
      <div class="content">
          <div class="recruit-cont">
            <div class="item">
              <div class="title">招聘职位</div>
              <div class="con">{{ detailData.jobName || '空' }}</div>
            </div>
            <div class="item">
              <div class="title">工作地点</div>
              <div class="con"  >{{ detailData.address || '空' }}</div>
            </div>
            <div class="item">
              <div class="title">薪资范围</div>
              <div class="con"  >{{ detailData.payDownRange }}K-{{ detailData.payUpRange }}K</div>
            </div>
            <div class="item">
              <div class="title">学历要求</div>
              <div class="con"  >{{ detailData.eduBack || '空' }}</div>
            </div>
            <div class="item">
              <div class="title">工作年限</div>
              <div class="con"  >{{ detailData.workYears }}</div>
            </div>
          </div>
          <div class="duty">
            <div class="title">岗位职责:</div>
            <div class="con" v-html="detailData.jobResponsibilities"></div>
          </div>
          <div class="duty">
            <div class="title">任职条件:</div>
            <div class="con" v-html="detailData.requirements"></div>
          </div>
          <div class="bor"></div>
          <div class="contact">
            <div class="contact-phone">联系电话:</div>
            <div class="phone">{{ detailData.phone || '空' }}</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import apis from '@/request/apis';

export default {
  props: ['id'],
  setup(props, { emit }) {
    const data = reactive({
      visible: true,
      detailData: {},
    });

    async function loadDetail() {
      const res = await apis.receptionJoinUs.getDetail({ id: props.id });
      data.detailData = res;
    }

    loadDetail();

    const close = () => {
      emit('closeDetail');
    };
    return {
      ...toRefs(data),
      close,
    };
  },
};
</script>

<style lang="scss">
.mask-dialog {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: auto;
  .dialog-box {
    width: 70.417vw;
    max-height: 70vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1.042vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .header {
      background-color: var(--baseColor1);
      font-size: 2.135vw;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 2.135vw;
      padding: 1.823vw 4.479vw;
      display: flex;
      justify-content: space-between;
      .close {
        font-weight: 500;
        font-size: 2.604vw;
      }
    }
    .content {
      flex: 1;
      background-color: #FFFFFF;
      padding-bottom: 1.667vw;
      overflow: scroll;
      .recruit-cont {
        display: flex;
        justify-content: space-between;
        padding: 0vw 1.042vw;
        background-color: #f9f9f9;
        .item {
          flex: 1;
          font-size: 1.25vw;
          font-weight: 500;
          color: #5F5F5F;
          padding: 0.938vw 0vw;
          > div {
            text-align: center;
          }
          .con {
            font-size: 0.938vw;
            font-weight: 500;
            color: #5F5F5F;
            margin-top: 0.521vw;
          }
        }
      }
      .duty {
        padding: 0 6.458vw;
        font-size: 1.146vw;
        font-weight: bold;
        color: var(--baseColor1);
        line-height: 2.135vw;
        margin-top: 1.667vw;
        .con {
          font-size: 0.938vw;
          color: #000;
          font-weight: 500;
        }
      }
      .bor {
        border-top: 0.052vw dotted #000;
        margin: 0vw 4.479vw;
        margin-top: 2.292vw;
      }
      .contact {
        padding: 0 6.458vw;
        font-size: 1.302vw;
        font-weight: bold;
        color: var(--baseColor1);
        line-height: 2.135vw;
        margin-top: 2.188vw;
        .contact-phone {
          font-size: 0.938vw;
          color: #000;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .mask-dialog {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: scroll;
    .dialog-box {
      width: 90vw;
      max-height: 70vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 2.667vw;
      overflow: hidden;
      // height: 60vh;
      .header {
        background-color: var(--baseColor1);
        font-size: 2.135vw;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 2.135vw;
        padding: 2.823vw 4.479vw;
        display: flex;
        justify-content: space-between;
        .close {
          font-weight: 500;
          font-size: 3.733vw;
        }
      }
      .content {
        background-color: #FFFFFF;
        padding-bottom: 1.667vw;
        .recruit-cont {
          display: flex;
          justify-content: space-between;
          padding: 0vw 1.042vw;
          background-color: #f9f9f9;
          .item {
            flex: 1;
            font-size: 1.25vw;
            font-weight: 500;
            color: #5F5F5F;
            padding: 0.938vw 0vw;
            > div {
              text-align: center;
            }
            .con {
              font-size: 1.867vw;
              font-weight: 500;
              color: #5F5F5F;
              margin-top: 1.333vw;
              line-height: 4.267vw;
              margin-bottom: 1.333vw;
            }
          }
        }
        .duty {
          padding: 0 6.458vw;
          font-size: 2.4vw;
          font-weight: bold;
          color: var(--baseColor1);
          line-height: 2.4vw;
          margin-top: 2.667vw;
          .con {
            font-size: 1.867vw;
            line-height: 4.267vw;
            color: #000;
            font-weight: 500;
            margin-top: 1.333vw;
            margin-bottom: 1.333vw;
          }
        }
        .bor {
          border-top: 0.052vw dotted #000;
          margin: 0vw 4.479vw;
          margin-top: 2.292vw;
        }
        .contact {
          padding: 0 6.458vw;
          font-size: 2.4vw;
          font-weight: bold;
          color: var(--baseColor1);
          line-height: 2.4vw;
          margin-top:2.4vw;
          .contact-phone {
            font-size: 2.4vw;
            color: #000;
            margin-top: 1.333vw;
          }
          .phone {
            margin-top: 1.333vw;

          }
        }
      }
    }
  }
}

</style>
