<template>
  <div class="item curr tx300" v-for="item in list" :key="item.id" ref="itemsRef" @click="detail(item.id)">
    <div class="header">
      <div class="title">{{ item.jobName }}</div>
      <div class="money">{{ item.payDownRange }} - {{ item.payUpRange }}k</div>
    </div>
    <div class="sub-title">主要岗位职责：</div>
    <div class="text-box">
      <div class="text" v-html="item.jobResponsibilities"></div>
    </div>
  </div>
  <div class="loading-text" v-if="loading">加载中...</div>
  <div class="next-btn curr" @click="nextPage" v-if="list.length < total">
    <img src="@/assets/images/join-image/down.png" alt="">
  </div>
  <dialog-item @closeDetail="closeDetail" :id="id" v-if="show"/>
</template>

<script>
import {
  nextTick, onMounted, reactive, toRefs,
} from 'vue';
import apis from '@/request/apis';
import { itemGsap } from '@/views/index/js/usePage';
import DialogItem from './reception-join-us-dialog.vue';

export default {
  components: {
    DialogItem,
  },
  setup() {
    const data = reactive({
      show: false,
      id: '',
      list: [],
      loading: false,
      itemsRef: [],
      total: 0,
      options: {
        current: 1,
        size: 3,
      },
    });
    const getPage = async () => {
      data.loading = true;
      try {
        const res = await apis.receptionJoinUs.getPage(data.options);
        const { records, total } = res;
        data.total = total;
        data.list.push(...records);
        nextTick(() => {
          data.itemsRef.forEach((item, index) => {
            if (!item.getAttribute('a')) {
              itemGsap(item, {
                x: 0,
                opacity: 1,
                duration: 0.3 + (index % data.options.size) / 10,
                ease: 'none',
                onComplete: () => {
                  item.setAttribute('a', item.id);
                },
              });
            }
          });
        });
      } finally {
        data.loading = false;
      }
    };
    onMounted(async () => {
      await getPage();
    });

    const nextPage = async () => {
      if (data.list.length >= data.total) return;
      if (data.loading) return;
      data.options.current++;
      await getPage();
    };

    const detail = (id) => {
      data.id = id;
      data.show = true;
    };
    const closeDetail = () => {
      data.show = false;
    };

    return {
      ...toRefs(data),
      nextPage,
      detail,
      closeDetail,
    };
  },
};
</script>
<style lang="scss" scoped>
.item {
  width: 100%;
  height: 13.438vw;
  padding: 1.563vw 4.167vw;
  background-color: #fff;
  margin-bottom: 4.688vw;
  border-bottom: 10px solid transparent;
  transition: all 0.5s;
  background-image: url('~@/assets/images/join-image/page04.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &:hover {
    border-bottom-color: var(--baseColor1);
    box-shadow: 0px 0px 30px 0px #E1E1E1;
  }
  .header {
    display: flex;
    .title,
    .money {
      height: 2.24vw;
      font-size: 1.771vw;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 1.771vw;
      margin-right: 4.167vw;
    }
    .money {
      font-size: 1.458vw;
    }
  }
  .sub-title {
    height: 2.5vw;
    font-size: 1.042vw;
    font-family: Microsoft New Tai Lue-Regular, Microsoft New Tai Lue;
    font-weight: 400;
    color: #333333;
    line-height: 1.979vw;
  }
  .text-box {
    overflow: hidden;
    height: 5.729vw;
    .text {
      font-size: 1.042vw;
      font-family: Microsoft New Tai Lue-Regular, Microsoft New Tai Lue;
      font-weight: 400;
      color: #333333;
      line-height: 1.979vw;
    }
  }
}
.loading-text {
  font-size: 0.625vw;
  text-align: center;
  margin: 1.042vw 0;
  color: #afafaf;
}
.next-btn {
  margin: 0 auto;
  width: 3.333vw;
  height: 3.333vw;
  transform: rotateZ(90deg);
  margin-bottom: 4.688vw;
}
@media screen and (max-width: 768px) {
  .item {
    margin: 0 1.333vw;
    width: auto;
    height: 25.438vw;
    padding: 1.563vw 4.167vw;
    background-color: #fff;
    margin-bottom: 4.688vw;
    border-bottom: 10px solid transparent;
    transition: all 0.5s;
    background-image: url('~@/assets/images/join-image/page04.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position-y: 50%;
    overflow: hidden;
    &:hover {
      border-bottom-color: var(--baseColor1);
      box-shadow: 0px 0px 30px 0px #E1E1E1;
    }
    .header {
      display: flex;
      .title,
      .money {
        height: 3.2vw;
        font-size: 3.2vw;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        line-height: 3.2vw;
        margin-right: 4.167vw;
      }
      .money {
        font-size: 1.458vw;
      }
    }
    .sub-title {
      height: 2.5vw;
      font-size: 2.667vw;
      font-family: Microsoft New Tai Lue-Regular, Microsoft New Tai Lue;
      font-weight: 400;
      color: #333333;
      line-height: 5.333vw;
      margin: 1.333vw 0;
    }
    .text-box {
      overflow: hidden;
      height: auto;
      .text {
        font-size: 1.867vw;
        font-family: Microsoft New Tai Lue-Regular, Microsoft New Tai Lue;
        font-weight: 400;
        color: #333333;
        line-height: 4.267vw;
      }
    }
  }
  .loading-text {
    font-size: 0.625vw;
    text-align: center;
    margin: 1.042vw 0;
    color: #afafaf;
  }
  .next-btn {
    margin: 0 auto;
    width: 10vw;
    height: 10vw;
    transform: rotateZ(90deg);
    margin-bottom: 4.688vw;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
</style>
