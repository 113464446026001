<template>
  <div class="reception-join-us">
    <div class="img-box">
      <img src="@/assets/images/join-image/page.png" alt=""/>
    </div>

    <div class="w content">
      <!-- 人才理念 -->
      <person-of-ability ref="personOfAbilityRef" :isView="personOfAbilityView"/>
      <!-- 数据列表 -->
      <items/>
    </div>
  </div>
</template>

<script>
import PersonOfAbility from './components/person-of-ability.vue';
import items from './components/items.vue';
import {
  onMounted, onUnmounted, reactive, toRefs,
} from 'vue';
import { isElementInViewport } from '@/views/index/js/usePage';

export default {
  components: {
    PersonOfAbility,
    items,
  },
  setup() {
    const data = reactive({
      personOfAbilityRef: null,
      personOfAbilityView: false,
    });

    const scroll = () => {
      data.personOfAbilityView = isElementInViewport(data.personOfAbilityRef.$el);
    };

    let app = null;
    onMounted(async () => {
      app = document.querySelector('#app');
      app.addEventListener('scroll', scroll);
      scroll();
    });
    onUnmounted(() => {
      app.removeEventListener('scroll', scroll);
      app.scrollTop = 0;
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.reception-join-us {
  .img-box {
    width: 100vw;
    height: 700px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 768px) {
  .reception-join-us {
    .img-box {
      width: 100vw;
      height: 50vw;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
  }
}
</style>
