<template>
  <div class="person-of-ability">
    <div class="left">
      <div class="title-img tx-600" ref="transformRef1">
        <img src="@/assets/images/join-image/page03.png" alt="">
      </div>
      <div class="text text1 tx-600" ref="transformRef2">穗深冷链(以下简称:”穗深“)为员工提供一个实现自身成长和发挥自我潜能的平台置身穗深冷链这个积极互助、注重员工培养的环境中，大家均有机会与一支年轻向上、丰富多元且经验丰富的专业团队一起，让职业生涯的发展更有收获。 </div>
      <div class="text text2 tx-600" ref="transformRef4">我们的核心价值观:诚信为本，责任为纲，专业专注，团队致胜，科创致远,我们视员工为最宝贵的财产，同时也是企业成功的关键，我们重视团队合作、提倡开放型文化，鼓励不断的改革与创新、积极承担社会责任。我们致力于发展和培养员工的专业知识、经验与判断力，并为他们提供广阔的职业发展机遇和个人发展空间。我们期待你加入我们，成为一名穗深的一员。</div>
    </div>
    <div class="right tx900" ref="transformRef3">
      <img src="@/assets/images/join-image/page02.png" alt="">
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
      transformRef2: null,
      transformRef3: null,
      transformRef4: null,
    });
    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          x: 0,
          opacity: 1,
          duration: 1,
        });
        itemGsap(data.transformRef2, {
          x: 0,
          opacity: 1,
          duration: 1.5,
        });
        itemGsap(data.transformRef4, {
          x: 0,
          opacity: 1,
          duration: 1.5,
        });
        itemGsap(data.transformRef3, {
          x: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.person-of-ability {
  height: 31.25vw;
  display: flex;
  margin-top: 3.021vw;
  margin-bottom: 6.146vw;

  .left {
    flex: 1;
    .title-img {
      width: 31.51vw;
      height: 5.938vw;
      margin-top: 3.906vw;
      margin-bottom: 4.271vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text {
      font-size: 0.938vw;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 1.875vw;
      text-indent: 1.875vw;
    }
    .text1 {
      margin-bottom: 3.646vw;
    }
  }
  .right {
    width: 28.75vw;
    height: 100%;
    margin-left: 6.667vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 768px) {
  .person-of-ability {
    height: auto;
    display: flex;
    margin-top: 3.021vw;
    margin-bottom: 6.146vw;
    padding: 0 1.333vw;

    .left {
      flex: 1;
      .title-img {
        width: 50vw;
        height: auto;
        margin-top: 3.906vw;
        margin-left: 4vw;
        margin-bottom: 2.271vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text {
        font-size: 1.867vw;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 4.267vw;
        text-indent: 3.733vw;
      }
      .text1 {
        margin-bottom: 2.646vw;
      }
    }
    .right {
      display: none;
      width: 0;
      height: 100%;
      margin-left: 6.667vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
